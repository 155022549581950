<template>
  <div class="send-message-form-wrapper">
    <form class="send-message-form" @submit.prevent="submit">
      <hub-text-field v-model="message" :disabled="isRequestPending"></hub-text-field>
      <hub-button :disabled="!isValid || isRequestPending" variant="icon" color="primary"><hub-icon name="send"></hub-icon></hub-button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '../../common/Icon.vue';
import TextField from '../../common/TextField.vue';
import Button from '../../common/Button.vue';

export default {
  components: {
    'hub-icon': Icon,
    'hub-text-field': TextField,
    'hub-button': Button
  },
  props: {
    inventionId: {
      type: String,
      required: true
    }
  },
  emits: ['cc'],
  data() {
    return {
      message: '',
      popupVisible: false,
      actions: [{ name: 'add-task', icon: 'sticker-plus-outline', label: 'Add Task' }]
    };
  },
  computed: {
    ...mapState({
      isRequestPending: s => s.messages.isCreateRequestPending || s.stream.isGetCollectionRequestPending
    }),
    isValid() {
      return this.message?.length;
    }
  },
  methods: {
    async submit() {
      if (!this.isValid || this.isRequestPending) {
        return;
      }

      await this.$store.dispatch('messages/create', { message: this.message, inventionId: this.inventionId });
      this.message = '';
      this.$emit('cc');
    },
    toggleActionVisibility() {
      this.popupVisible = !this.popupVisible;
    },
    trigger(action) {
      this.$emit(action.name);
      this.popupVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.send-message-form-wrapper {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 0.5rem;
  position: relative;
}

.send-message-form {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 0.5rem;
  position: relative;
}

.action-list-wrapper {
  position: relative;
}

.action-list {
  position: absolute;
  bottom: 40px;
  left: -12px;
  border: 1px solid var(--theme-on-background-accent);
  color: var(--theme-on-background);
  background: var(--theme-background);
  border-radius: 2px;
  transition: opacity 0.5s;
  display: none;

  &.active {
    display: block;
  }

  &:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--theme-on-background-accent);
    transform: translate(19px, 8px);
    display: block;
    content: '';
    bottom: 0;
    position: absolute;
  }

  .action-list-item {
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: max-content 100px;
    grid-template-rows: minmax(0, 1fr);
    padding: 0.5rem 1rem;
    align-items: center;

    label {
      font-weight: 700;
      font-size: 0.85rem;
      cursor: inherit;
    }

    &:hover {
      background-color: var(--theme-highlight);
      cursor: pointer;
    }
  }
}
</style>
